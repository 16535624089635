import router from "../router/index.js";
import { useCompanyStore } from "stores/company";
import { Notify, Dialog } from "quasar";
import { useUserStore } from "src/stores/user.js";
import { usePreferencesStore } from "src/stores/preferences.js";
import { useThreadStore } from "src/stores/thread.js";
import { ref } from "vue";
import { useGlobalUiStore } from "src/stores/globalUi.js";
import { useI18n } from "vue-i18n";
import { api } from "src/boot/axios.js";
export function getCurrenciesFromCountries(countries) {
  let currencies = [];
  countries.forEach((country) => {
    currencies.push({
      value: country.currency.id,
      label: country.currency.code,
      flag: getFlag(country.iso2),
      symbol: country.currency.symbol,
    });
  });
  return currencies;
}

export function getFlag(iso) {
  return window.location.origin + "/assets/flags/svg/" + iso + ".svg";
}

export function notifyUser(
  message,
  icon = "svguse:assets/icons/info.svg#icon",
  color = "green",
) {
  Notify.create({
    position: "top",
    color: color,
    message,
    icon,
    timeout: 1500,
  });
}

export function alertDialog(title, message, onOkCallback, persistent = false) {
  Dialog.create({
    title,
    message,
    persistent,
  }).onOk(onOkCallback);
}

export function fileExtension(name) {
  return name.split(".").at(-1);
}

export function ExtensionToFileType(extension) {
  switch (extension) {
    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
    case "bmp":
    case "webp":
      return "image";
    case "doc":
    case "docx":
    case "txt":
      return "doc";
    case "pdf":
      return "pdf";
    case "xls":
    case "xlsx":
      return "xls";
    default:
      return "unknown";
  }
}

export function getSelectedCompany() {
  const companyStore = useCompanyStore();
  return companyStore.getCurrentCompanyId;
}

export function formatMoney(amount, withCurrency = false) {
  if (amount === null || amount === "") return null;
  let currency = "";
  if (withCurrency) currency = " DZD";

  return (
    Number.parseFloat(amount)
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + currency
  );
}

export function convertSize(sizeInBytes, absoluteValue = false) {
  let mb = sizeInBytes / 1000000;

  if (absoluteValue) return mb;

  if (mb < 1) return Number((sizeInBytes / 1000).toFixed(2)) + " KB";

  return Number(mb.toFixed(2)) + " MB";
}

export function copyToClipboard(text) {
  navigator.clipboard.writeText(text);
  Notify.create({
    position: "top-right",
    color: "primary",
    message: "copié !",
    icon: "svguse:assets/icons/copy.svg#icon",
    timeout: 1500,
  });
}

//todo add as helper
export function copyAbsoluteUrl(path) {
  let absolutePath = window.location.origin + "/app/#" + path;
  copyToClipboard(absolutePath);
}

export function MoreThan9(number) {
  if (number > 9) return "9+";
  return `${number}`;
}

// clear all stores
export function clearAllStores() {
  const stores = {
    store1: useUserStore(),
    store2: useThreadStore(),
    store3: useCompanyStore(),
    store4: usePreferencesStore(),
  };
  for (const key in stores) {
    stores[key].$reset(); // Reset each store
  }
}

export const isReactNative =
  window.hasOwnProperty("ReactNativeWebView") ?? false;

export const isMobile = ref(window.matchMedia("(max-width: 768px)").matches);
export const isTablet = ref(window.matchMedia("(max-width: 1024px)").matches);

export const displayMobileBanner = ref(
  (isMobile || isTablet) && isHandledDevice() && !isReactNative,
);
// Detect screen change
// if screen size is smaller than 768px = md in tailwind
// isMobile is set to true
window.addEventListener("resize", () => {
  isMobile.value = window.matchMedia("(max-width: 768px)").matches;
  isTablet.value = window.matchMedia("(max-width: 1024px)").matches;
});

export function uploadFiles(newFiles) {
  let filesArray = [...newFiles];
  const maxsize = 24;
  Object.keys(filesArray).forEach(function (index) {
    if (convertSize(filesArray[index].size, "mb") > maxsize) {
      Notify.create({
        position: "top-right",
        color: "red",
        message: `le document ${filesArray[index].name} est plus grand que 24 MB`,
      });

      delete filesArray[index];
    }
  });
  return filesArray.filter((value, index) => value);
}

export function generateUniqueFileId(file) {
  return file.name + (Math.random() + 1).toString(36).substring(7) + file.id;
}

//handle notificaion click routing per notification type
export function notificationClickHandler(notification, router) {
  let routeOptions = {
    name: null,
    params: {},
    query: {},
    force: true,
  };

  switch (notification.type) {
    case "App\\Notifications\\AttachmentProcessedNotification":
      routeOptions.name = "statements";
      routeOptions.params.company = notification.sender_org.id;
      break;

    case "App\\Notifications\\AttachmentUploadedNotification":
      routeOptions.name = "storage";
      routeOptions.params.company = notification.sender_org.id;
      break;

    case "App\\Notifications\\DisassociationNotification":
      routeOptions.name = "tenancy.companies";
      break;

    case "App\\Notifications\\InvitationAcceptedNotification":
      if (notification.invitation.type === "company") {
        routeOptions.name = "storage";
        routeOptions.params.company = notification.sender_org.id;
      }

      if (notification.invitation.type === "firm") {
        routeOptions.name = "company.accountant";
        routeOptions.params.company = notification.invitation.entity.id;
      }

      if (notification.invitation.type === "firm_user") {
        routeOptions.name = "firm.users";
        routeOptions.params.firmId = notification.invitation.entity_id;
      }

      if (notification.invitation.type === "company_collaborator") {
        routeOptions.name = "collaborators";
        routeOptions.params.company = notification.receiver_org.id;
      }

      break;
    case "App\\Notifications\\InvoiceCollector\\IncomingEmailProcessedNotification":
      routeOptions.name = "storage";
      routeOptions.query.partner_id = notification.partner_id;
      routeOptions.params.company = notification.receiver_org.id;

    case "App\\Notifications\\InvoiceCollector\\BulkIncomingEmailsTreatedNotification":
      routeOptions.name = "storage";
      routeOptions.query.partner_id = notification?.partner_id;
      routeOptions.params.company = notification?.receiver_org?.id;
      break;
    default:
      break;
  }

  router.push(routeOptions);
}

// Get the OS Type of the Mobile Device
export function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
}

// Get the asset from the backend
export function getAssetFromBackend(path) {
  return window.location.origin + "/assets/" + path;
}

// Check if the user agent is a handled device ( Mobile/Tablet )
export function isHandledDevice() {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4),
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
}


export function isWalvis() {
  return process.env.isWalvis;
}

export function isCompta() {
  return process.env.isCompta;
}

export function dateToLocaleDateString(
  date,
  formatOptions = { timeStyle: "short", hourCycle: "h12" },
) {
  date = new Date(date);
  let isNow = new Date().toLocaleDateString() === date.toLocaleDateString();

  //TODO: FIX THE ERROR WITH TRANSLATION t("at")
  return isNow
    ? date.toLocaleTimeString(process.env.app_locale, formatOptions)
    : date.toLocaleDateString(process.env.app_locale, {
        ...formatOptions,
        timeStyle: undefined,
      }) +
        " " +
        /*useI18n().t("at") +*/ " " +
        date.toLocaleTimeString(process.env.app_locale, { ...formatOptions });
}

export function isProducation() {
  return process.env.app_env === "production";
}

/*
 * push to route history without re rendering
 * or refreshing the page use this when
 * you want to navigate withing the
 * same page, example: tabs
 */
export function pushUrlSilently(routerObject) {
  let path = router.resolve(routerObject).href;
  history.pushState("", document.title, path);
}

export async function getCountriesList() {

  const { status, data, error } = await api.get("v1/countries");
  return { status, data, error };
}