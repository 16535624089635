{
  "Ressource non trouvée": "Ressource non trouvée",
  "retour": "retour",
  "accueil": "accueil",
  "une erreur s'est produite": "une erreur s'est produite",
  "My Space": "Mon Espace",
  "Supprimer le compte": "Supprimer le compte",
  " Votre compte sera définitivement supprimé dans": " Votre compte sera définitivement supprimé dans",
  "60 jours": "60 jours",
  "Si vous vous connectez pendant cette période, votre compte sera automatiquement": "Si vous vous connectez pendant cette période, votre compte sera automatiquement",
  "restauré": "restauré",
  "Annuler": "Annuler",
  "Confirmer": "Confirmer",
  "Besoin d'aide ?": "Besoin d'aide ?",
  "Avertissement de suppression": "Avertissement de suppression",
  "Supprimer": "Supprimer",
  "Vous avez encore des fichiers en cours de téléchargement, êtes-vous sûr de vouloir annuler cette action ?": "Vous avez encore des fichiers en cours de téléchargement, êtes-vous sûr de vouloir annuler cette action ?",
  "Annuler le téléchargement": "Annuler le téléchargement",
  "Continuer le téléchargement": "Continuer le téléchargement",
  "Catégorie de document": "Catégorie de document",
  "Ce fichier n'a pas de catégorie, veuillez ajouter une catégorie à ce fichier": "Ce fichier n'a pas de catégorie, veuillez ajouter une catégorie à ce fichier",
  "La modification d'une catégorie de document traité peut entraîner une perte de données": "La modification d'une catégorie de document traité peut entraîner une perte de données",
  "Choisissez une catégorie pour la lier à votre(s) fichier(s)": "Choisissez une catégorie pour la lier à votre(s) fichier(s)",
  "fichiers sélectionnés": "fichiers sélectionnés",
  "Traiter": "Traiter",
  "cercles": "cercles",
  "Un cercle est un groupe de membres qui peut comprendre des clients, des fournisseurs, des employés et des associés.": "Un cercle est un groupe de membres qui peut comprendre des clients, des fournisseurs, des employés et des associés.",
  "non classé": "non classé",
  "les membres qui n'appartiennent pas à un cercle spécifique ne sont pas classés.": "les membres qui n'appartiennent pas à un cercle spécifique ne sont pas classés.",
  "Editer le": "Editer le",
  "Nouveau": "Nouveau",
  "cercle": "cercle",
  "Nom du cercle": "Nom du cercle",
  "Cercle": "Cercle",
  "Couleur": "Couleur",
  "Enregistrer": "Enregistrer",
  "Supprimer le cercle": "Supprimer le cercle",
  "ce cercle sera définitivement supprimé et détaché de tous ses membres": "ce cercle sera définitivement supprimé et détaché de tous ses membres",
  "Détacher le cercle": "Détacher le cercle",
  "Êtes-vous sûr de vouloir détacher ce cercle du membre ": "Êtes-vous sûr de vouloir détacher ce cercle du membre ",
  "Détacher": "Détacher",
  "documents": "documents",
  "Document non supporté": "Document non supporté",
  "Document non trouvé": "Document non trouvé",
  "Nous ne prenons pas encore en charge les documents": "Nous ne prenons pas encore en charge les documents",
  "Ce document pourrait être archivé ou supprimé": "Ce document pourrait être archivé ou supprimé",
  "Document téléchargé avec succès dans votre documents": "Document téléchargé avec succès dans votre documents",
  "Ouvrir l'emplacement": "Ouvrir l'emplacement",
  "Aperçu": "Aperçu",
  "Télécharger": "Télécharger",
  "Téléverser": "Téléverser",
  "Rechercher": "Rechercher",
  "Voir les invitations": "Voir les invitations",
  "Mon profil": "Mon profil",
  "Déconnexion": "Déconnexion",
  "nouvelle invitation reçue de ": "nouvelle invitation reçue de ",
  "Invitation rejecte par ": "Invitation rejecte par ",
  "a annulé l'invitation": "a annulé l'invitation",
  "a accepté votre invitation": "a accepté votre invitation",
  "s'est dissocié de": "s'est dissocié de",
  "Email": "Email",
  "Invitation envoyée à l'expert-comptable": "Invitation envoyée à l'expert-comptable",
  "Invitation envoyée à l'entreprise": "Invitation envoyée à l'entreprise",
  "user is not on-boarded": "l'utilisateur n'est pas intégré",
  "Vu": "Vu",
  "Editer": "Editer",
  "Gestion des fichiers": "Gestion des fichiers",
  "Téléchargez un fichier ou créez un dossier": "Téléchargez un fichier ou créez un dossier",
  "Créer un dossier": "Créer un dossier",
  "Sélectionner des fichiers": "Sélectionner des fichiers",
  "Fichiers en cours de téléchargement": "Fichiers en cours de téléchargement",
  "Aucun document téléchargé": "Aucun document téléchargé",
  "Aucun document n'a encore été téléchargé sur la plateforme.": "Aucun document n'a encore été téléchargé sur la plateforme.",
  "Général": "Général",
  "Restez à l'écoute, pas encore de mises à jour": "Restez à l'écoute, pas encore de mises à jour",
  "Aucune mise à jour ou notification ne vous a été envoyée pour le moment.": "Aucune mise à jour ou notification ne vous a été envoyée pour le moment.",
  "Voir toutes les notifications": "Voir toutes les notifications",
  "L'utilisateur n'est pas enregistré": "L'utilisateur n'est pas enregistré",
  "Editer le membre": "Editer le membre",
  "Nouveau membre": "Nouveau membre",
  "Nom du membre": "Nom du membre",
  "annuler": "annuler",
  "Mettre à jour": "Mettre à jour",
  "Créer": "Créer",
  "Details d'agence": "Détails d'agence",
  "nom de l'agence": "nom de l'agence",
  "Identifiant unique": "Identifiant unique",
  "Adresse": "Adresse",
  "Pays": "Pays",
  "Proviance": "Proviance",
  "Rue": "Rue",
  "Code Postal": "Code Postal",
  "Détails de l'entreprise": "Détails de l'entreprise",
  "Nom de l'entreprise": "Nom de l'entreprise",
  "Taille de l'entreprise": "Taille de l'entreprise",
  "Address": "Adresse",
  "Street": "Rue",
  "Code postal": "Code postal",
  "Information Fiscale": "Information Fiscale",
  "Additional information": "Informations supplémentaires",
  "Legal Information": "Informations légales",
  "Information administrative": "Information administrative",
  "Filtrer la recherche": "Filtrer la recherche",
  "Membre": "Membre",
  "Catégorie": "Catégorie",
  "Date": "Date",
  "Fermer": "Fermer",
  "Réinitialiser": "Réinitialiser",
  "Appliquer les filtres": "Appliquer les filtres",
  "integration": "integration",
  "integration in proccess": "intégration en cours",
  "bookeeping SaaS": "SaaS de comptabilité",
  "connect": "connecter",
  "to": "à",
  "allows you to": "vous permet de",
  "import invoices to": "importer des factures vers",
  "Manage your invoices": "Gérer vos factures",
  "keep your accountant in sync": "garder votre comptable synchronisé",
  "By clicking on allow access, you authorize": "En cliquant sur autoriser l'accès, vous autorisez",
  "to use your information in accordance with its": "à utiliser vos informations conformément à sa",
  "Privacy Policy": "Politique de confidentialité",
  "open": "ouvrir",
  "Détail du relevé": "Détail du relevé",
  "Document": "Document",
  "Vous pouvez masquer ou afficher ce champ dans le relevé": "Vous pouvez masquer ou afficher ce champ dans le relevé",
  "Reference": "Référence",
  "Label": "Libellé",
  "Journal": "Journal",
  "Détails": "Détails",
  "Actions": "Actions",
  "Cette catégorie ne supporte pas les relevés, le relevé a été supprimé.": "Cette catégorie ne supporte pas les relevés, le relevé a été supprimé.",
  "Relevé supprimé": "Relevé supprimé",
  "Le relevé que vous êtes en train de modifier a été supprimée par un autre utilisateur.": "Le relevé que vous êtes en train de modifier a été supprimée par un autre utilisateur.",
  "Éditer": "Éditer",
  "Create": "Créer",
  "Dossier": "Dossier",
  "Nom": "Nom",
  "Prenom": "Prénom",
  "Envoi du fichier": "Envoi du fichier",
  "Fichiers non traités": "Fichiers non traités",
  "Répertoire de documents": "Répertoire de documents",
  "Sélectionnez les dossiers dans lesquels vous souhaitez inclure vos documents": "Sélectionnez les dossiers dans lesquels vous souhaitez inclure vos documents",
  "sélectionner": "sélectionner",
  "Rechargement...": "Rechargement...",
  "Accueil": "Accueil",
  "Vue en liste": "Vue en liste",
  "Vue en grile": "Vue en grile",
  "sélectionner tout": "sélectionner tout",
  "desélectionner tout": "desélectionner tout",
  "Start thread": "Démarrer un fil",
  "Vous essayez de supprimer un dossier qui peut contenir des documents. Tous les documents seront supprimés. Êtes-vous sûr ?": "Vous essayez de supprimer un dossier qui peut contenir des documents. Tous les documents seront supprimés. Êtes-vous sûr ?",
  "Êtes-vous sûr de vouloir supprimer ce document ?": "Êtes-vous sûr de vouloir supprimer ce document ?",
  "Vous essayez de supprimer un ou plusieurs dossiers qui peuvent contenir des documents. Tous les documents seront supprimés. Êtes-vous sûr ?": "Vous essayez de supprimer un ou plusieurs dossiers qui peuvent contenir des documents. Tous les documents seront supprimés. Êtes-vous sûr ?",
  "Êtes-vous sûr de vouloir supprimer": "Êtes-vous sûr de vouloir supprimer",
  "Inviter une entreprise": "Inviter une entreprise",
  "Invitez votre client et gérez ses documents comptables": "Invitez votre client et gérez ses documents comptables",
  "Entrez l'email de votre client": "Entrez l'email de votre client",
  "Déplacer": "Déplacer",
  "Copier le lien": "Copier le lien",
  "Détails du dossier": "Détails du dossier",
  "Discussions": "Discussions",
  "Nom du document": "Nom du document",
  "Format": "Format",
  "Taille": "Taille",
  "envoyer un message dans": "envoyer un message dans",
  "Unarchive this thread to respond to this message": "Désarchivez ce fil pour répondre à ce message",
  "Unarchive": "Désarchiver",
  "générale": "générale",
  "removed": "supprimé",
  "Détails du fil de discussion": "Détails du fil de discussion",
  "informations sur ce fil de discussion": "informations sur ce fil de discussion",
  "Sujet": "Sujet",
  "general": "général",
  "Nom du fil de discussion": "Nom du fil de discussion",
  "copier URL": "copier URL",
  "Archiver": "Archiver",
  "Aucun document envoyé": "Aucun document envoyé",
  "Aucun document n'a encore été téléchargé dans ce fil de discussion.": "Aucun document n'a encore été téléchargé dans ce fil de discussion.",
  "Thread": "Fil de discussion",
  "Files": "Fichiers",
  "Ajouter discussion": "Ajouter discussion",
  "Archived threads": "Fils de discussion archivés",
  "Commencer une discussion": "Commencer une discussion",
  "Nom de la discussion": "Nom de la discussion",
  "Note": "Note",
  "Cette discussion sera ajoutée au sujet ": "Cette discussion sera ajoutée au sujet ",
  "Vous devez d'abord avoir un comptable pour créer une discussion.": "Vous devez d'abord avoir un comptable pour créer une discussion.",
  "File": "Fichier",
  "Hide file": "Masquer le fichier",
  "View file": "Voir le fichier",
  "Copy directory": "Copier le répertoire",
  "Sélectionnez un fil de discussion à gauche": "Sélectionnez un fil de discussion à gauche",
  "File sent": "Fichier envoyé",
  "New thread, send your message": "Nouveau fil, envoyez votre message",
  "Thread details": "Détails du fil",
  "Convertir votre document en relevés": "Convertir votre document en relevés",
  "Traiter le document avec AI": "Traiter le document avec AI",
  "Comptable: Commencez avec": "Comptable: Commencez avec",
  "Choisissez une catégorie": "Choisissez une catégorie",
  "Aucune invitation pour le moment": "Aucune invitation pour le moment",
  "cabinet n'est invité": "cabinet n'est invité",
  "Votre liste d'invitations envoyées est vide": "Votre liste d'invitations envoyées est vide",
  "Votre file d'attente d'invitations est actuellement vide": "Votre file d'attente d'invitations est actuellement vide",
  "Inviter": "Inviter",
  "Aucun fil de discussion commencé": "Aucun fil de discussion commencé",
  "Vous n'avez aucun fil de discussion à afficher": "Vous n'avez aucun fil de discussion à afficher",
  "Succès": "Succès",
  "Dashboard": "Tableau de bord",
  "Received Invites": "Invitations reçues",
  "Sent Invites": "Invitations envoyées",
  "Tableau de bord": "Tableau de bord",
  "Documents": "Documents",
  "Relevés": "Relevés",
  "Membres": "Membres",
  "Paramètres": "Paramètres",
  "Alerte de dissociation": "Alerte de dissociation",
  "Vous n'avez aucune entreprise dans votre espace de travail": "Vous n'avez aucune entreprise dans votre espace de travail",
  "Invitez vos clients pour profiter de la plateforme": "Invitez vos clients pour profiter de la plateforme",
  "La dissociation avec cette entreprise signifie que vous perdrez toutes les données des clients": "La dissociation avec cette entreprise signifie que vous perdrez toutes les données des clients",
  "Êtes-vous sûr de vouloir dissocier": "Êtes-vous sûr de vouloir dissocier",
  "Oops. Nothing here...": "Oops. Rien ici...",
  "Invitations": "Invitations",
  "Reçues": "Reçues",
  "Envoyées": "Envoyées",
  "Annuler l'invitation": "Annuler l'invitation",
  "Aucun": "Aucun",
  "compte": "compte",
  "cabinet": "cabinet",
  "Récupération des demandes...": "Récupération des demandes...",
  "Inviter un comptable": "Inviter un comptable",
  "Invitez votre comptable et partagez vos documents avec": "Invitez votre comptable et partagez vos documents avec",
  "Entrez l'email de votre comptable": "Entrez l'email de votre comptable",
  "Invitation accepté": "Invitation acceptée",
  "Détails du comptable": "Détails du comptable",
  "Dissocier": "Dissocier",
  "Disassociate": "Dissocier",
  "Chargement...": "Chargement...",
  "Notifications": "Notifications",
  "Qui êtes-vous ?": "Qui êtes-vous ?",
  "Choisissez le statut qui vous décrit le mieux ": "Choisissez le statut qui vous décrit le mieux ",
  "Un Cabinet comptable": "Un Cabinet comptable",
  "Choisissez cette option si vous êtes un commissaire au compte, expert comptable ou comptable agrée dans un cabinet, gérant plusieurs clients.": "Choisissez cette option si vous êtes un commissaire au compte, expert comptable ou comptable agrée dans un cabinet, gérant plusieurs clients.",
  "Une entreprise": "Une entreprise",
  "Choisissez cette option si vous êtes un entrepreneur, ou un cadre comptable et financier interne dans une entreprise. ": "Choisissez cette option si vous êtes un entrepreneur, ou un cadre comptable et financier interne dans une entreprise. ",
  "Suivant": "Suivant",
  "Vous recherchez un comptable ?": "Vous recherchez un comptable ?",
  "Company?": "Entreprise?",
  "Rejoindre": "Rejoindre",
  "Veuillez fournir les informations suivantes pour garantir une expérience d'intégration fluide": "Veuillez fournir les informations suivantes pour garantir une expérience d'intégration fluide",
  "Numéro d'agrément": "Numéro d'agrément",
  "Wilaya": "Wilaya",
  "Combien de clients prévoyez-vous de gérer ?": "Combien de clients prévoyez-vous de gérer ?",
  "Retour": "Retour",
  "Vous recherchez un client ?": "Vous recherchez un client ?",
  "Accountant?": "Comptable?",
  "Accountant": "Comptable",
  "Rejoignez-nous": "Rejoignez-nous",
  "Embarquez dans un processus d'intégration fluide pour améliorer votre expérience avec nous": "Embarquez dans un processus d'intégration fluide pour améliorer votre expérience avec nous",
  "Taille de l'agence": "Taille de l'agence",
  "skip": "skip",
  "Tous les cercles": "Tous les cercles",
  "Non classé": "Non classé",
  "Nouveau cercle": "Nouveau cercle",
  "Ajouter un membre": "Ajouter un membre",
  "Êtes-vous sûr de vouloir supprimer le membre ": "Êtes-vous sûr de vouloir supprimer le membre ",
  "le cercle": "le cercle",
  "vient d'être créé": "vient d'être créé",
  "vient d'être edité": "vient d'être édité",
  "vient d'être supprimé": "vient d'être supprimé",
  "le membre": "le membre",
  "vient d'être détaché du circle": "vient d'être détaché du cercle",
  "Paramètres du compte": "Paramètres du compte",
  "Détails du compte": "Détails du compte",
  "Nom complet": "Nom complet",
  "N° du Téléphone": "N° du Téléphone",
  "Sécurité": "Sécurité",
  "Old password": "Ancien mot de passe",
  "New password": "Nouveau mot de passe",
  "Confirm new password": "Confirmer le nouveau mot de passe",
  "Change password": "Changer le mot de passe",
  "Êtes-vous sûr de vouloir supprimer ce compte ? La suppression de ce compte entraînera la suppression de toutes les données qui y sont associées.": "Êtes-vous sûr de vouloir supprimer ce compte ? La suppression de ce compte entraînera la suppression de toutes les données qui y sont associées.",
  "Liste des catégories": "Liste des catégories",
  "Nouvelle catégorie": "Nouvelle catégorie",
  "All fields": "Tous les champs",
  "Il s'agit d'une catégorie par défaut qui appartient au système": "Il s'agit d'une catégorie par défaut qui appartient au système",
  "Champs de catégorie": "Champs de catégorie",
  "Tous les champs": "Tous les champs",
  "Ces champs appartiennent à toutes les catégories existantes sur la plateforme.": "Ces champs appartiennent à toutes les catégories existantes sur la plateforme.",
  "Ajouter un champ": "Ajouter un champ",
  "Connected": "Connecté",
  "Last uplaod at": "Dernier téléchargement à",
  "Decconecter": "Déconnecter",
  "Connecter": "Connecter",
  "Modifier la catégorie": "Modifier la catégorie",
  "Nom de la catégorie": "Nom de la catégorie",
  "statement_row": "Ligne de relevé",
  "statement_desc": "Cela permettra aux documents téléchargés associés à cette catégorie de générer une ligne dans le tableau de déclaration",
  "statement": "statement",
  "disable": "désactiver",
  "enable": "activer",
  "create": "créer",
  "Êtes-vous sûr de vouloir supprimer la catégorie": "Êtes-vous sûr de vouloir supprimer la catégorie",
  "Modifier le champ": "Modifier le champ",
  "Nouveau champ": "Nouveau champ",
  "Nom du champ": "Nom du champ",
  "Type de champ": "Type de champ",
  "Entrez vos options": "Entrez vos options",
  "Sélectionnez les catégories": "Sélectionnez les catégories",
  "Êtes-vous sûr de vouloir supprimer le champ": "Êtes-vous sûr de vouloir supprimer le champ",
  "Detach Field": "Détacher le champ",
  "Êtes-vous sûr de vouloir détacher le champ": "Êtes-vous sûr de vouloir détacher le champ",
  "de la catégorie": "de la catégorie",
  "Texte": "Texte",
  "Zone de texte": "Zone de texte",
  "Sélection": "Sélection",
  "Type": "Type",
  "Options": "Options",
  "Catégories": "Catégories",
  "Integrations": "Intégrations",
  "integration failed try again later or contact support": "l'intégration a échoué, réessayez plus tard ou contactez le support",
  "integration avec": "intégration avec",
  "fait avec success": "fait avec succès",
  "annulée": "annulée",
  "export": "exporter",
  "Aucune relevé traitée pour le moment": "Aucun relevé traité pour le moment",
  "Modifier": "Modifier",
  "Certaines données peuvent être manquantes ou incorrectes": "Certaines données peuvent être manquantes ou incorrectes",
  "Ce document doit être vérifié par le comptable": "Ce document doit être vérifié par le comptable",
  "Échec": "Échec",
  "En attente": "En attente",
  "Alerte": "Alerte",
  "Catégorie supprimée": "Catégorie supprimée",
  "Traité": "Traité",
  "Êtes-vous sûr de vouloir supprimer le relevé": "Êtes-vous sûr de vouloir supprimer le relevé",
  "La page ne peut pas être consultée sur la version mobile": "La page ne peut pas être consultée sur la version mobile",
  "Le journal n'est pas disponible sur mobile. Veuillez vous rendre sur votre navigateur Web pour le consulter": "Le journal n'est pas disponible sur mobile. Veuillez vous rendre sur votre navigateur Web pour le consulter",
  "Revenir au dashboard": "Revenir au tableau de bord",
  "Référence": "Référence",
  "Total": "Total",
  "Compte": "Compte",
  "Libellé": "Libellé",
  "Débit": "Débit",
  "Crédit": "Crédit",
  "un autre utilisateur a supprimé un relevé(s)": "un autre utilisateur a supprimé un relevé(s)",
  "Modifier le document": "Modifier le document",
  "Répertoire du document": "Répertoire du document",
  "Champs": "Champs",
  "Récupération du document...": "Récupération du document...",
  "Document enregistré": "Document enregistré",
  "Détails du document": "Détails du document",
  "Entamez une discussion": "Entamez une discussion",
  "Propriétaire": "Propriétaire",
  "Date de téléchargement": "Date de téléchargement",
  "NOUVEAU": "NOUVEAU",
  "Uplaod": "Télécharger",
  "Personnalisez votre environnement": "Personnalisez votre environnement",
  "Personnalisez votre tableau de bord pour correspondre à vos préférences de flux de travail. Une fois votre  espace de travail préparé, vous débloquerez tout le potentiel de la plateforme.": "Personnalisez votre tableau de bord pour correspondre à vos préférences de flux de travail. Une fois votre espace de travail préparé, vous débloquerez tout le potentiel de la plateforme.",
  "Personnaliser": "Personnaliser",
  "Documents partagés": "Documents partagés",
  "You have successfully uploaded": "Vous avez téléchargé avec succès",
  "To complete the process, please provide the necessary information regarding your documents.": "Pour terminer le processus, veuillez fournir les informations nécessaires concernant vos documents.",
  "Accéder": "Accéder",
  "ce dossier a été supprimée par un autre utilisateur": "ce dossier a été supprimé par un autre utilisateur",
  "un autre utilisateur a supprimé un(des) dossier(s)": "un autre utilisateur a supprimé un(des) dossier(s)",
  "un autre utilisateur a supprimé un(des) fichier(s)": "un autre utilisateur a supprimé un(des) fichier(s)",
  "Documents non classés": "Documents non classés",
  "documents sélectionnés": "documents sélectionnés",
  "Traiter avec l'IA": "Traiter avec l'IA",
  "Changer l'emplacement": "Changer l'emplacement",
  "Process file": "Processus de fichier",
  "document": "document",
  "move": "déplacer",
  "delete": "supprimer",
  "Emplacement": "Emplacement",
  "Traitement de l'IA": "Traitement de l'IA",
  "un autre utilisateur a supprimé un(des) document(s)": "un autre utilisateur a supprimé un(des) document(s)",
  "More options": "Plus d'options",
  "Invites": "Invitations",
  "My accountant": "Mon comptable",
  "Cancel": "Annuler",
  "Save changes": "Enregistrer les modifications",
  "Invite": "Invité",
  "invite": "invité",
  "Firms": "cabinet comptable",
  "Firm": "cabinet comptable",
  "Companies": "Entreprises",
  "Users": "Utilisateurs",
  "Mes entreprise": "Mes entreprise",
  "Rechercher les enreprises": "Rechercher les enreprises",
  "Ajouter une agence de comtabilite": "Ajouter une agence de comtabilite",
  "Details": "Détails",
  "documents without currency will not appear in this filtering": "les documents sans devise n'apparaîtront pas dans ce filtrage",
  "Export statements": "Export statements",
  "Filter by currency": "Filtrage par device",
  "currency": "currency",
  "default currency": "devise par défaut",
  "Supprimer l'entreprise": "Supprimer l'entreprise",
  "Êtes-vous sûr de vouloir supprimer l'entreprise? La suppression de l'entreprise entraînera la suppression de toutes les données qui y sont associées.": "Êtes-vous sûr de vouloir supprimer l'entreprise? La suppression de l'entreprise entraînera la suppression de toutes les données qui y sont associées.",
  "Ajouter": "Ajouter",
  "choisissez un cabinet comptable": "Choisissez un cabinet comptable",
  "choisissez une entreprise": "Choisissez une entreprise",
  "Sélectionner un cabinet comptable pour l'attribuer à l'entreprise": "Sélectionner un cabinet comptable pour l'attribuer à l'entreprise",
  "choisir une entreprise pour travailler avec votre cabinet comptable": "Choisir une entreprise pour travailler avec votre cabinet comptable",
  "Install mobile app": "Installer l'application mobile",
  "For a smoother experience": "Pour une expérience plus fluide",
  "Détails de l'utilisateur": "Détails de l'utilisateur",
  "Numéro de téléphone": "Numéro de téléphone",
  "Entreprises affiliées": "Entreprises affiliées",
  "Dissociation comptable": "Dissociation comptable",
  "Dissocier le comptable": "Dissocier le comptable",
  "Ce comptable perdra tout accès. Si vous souhaitez travailler avec lui, vous devrez l'inviter à nouveau. Continuer ?": "Ce comptable perdra tout accès. Si vous souhaitez travailler avec lui, vous devrez l'inviter à nouveau. Continuer ?",
  "en supprimant cette entreprise, vous perdrez toutes les données qui lui sont associées": "En supprimant cette entreprise, vous perdrez toutes les données qui lui sont associées.",
  "Êtes-vous sûr de vouloir supprimer cette entreprise ?": "Êtes-vous sûr de vouloir supprimer cette entreprise ?",
  "Desassocier": "Dissocier",
  "Entreprise Id": "Identifiant de l'entreprise",
  "Supprimer le cabinet comptable": "Supprimer le cabinet comptable",
  "En supprimant ce cabinet comptable, vous perdrez le contact avec vos clients et éventuellement avec vos comptables.": "En supprimant ce cabinet comptable, vous perdrez le contact avec vos clients et éventuellement avec vos comptables.",
  "Êtes-vous sûr de vouloir supprimer ce cabinet comptable ?": "Êtes-vous sûr de vouloir supprimer ce cabinet comptable ?",
  "Inviter un comptable pour votre cabinet": "Inviter un comptable à votre cabinet",
  "Envoyez ce lien au comptable que vous souhaitez inviter": "Envoyez ce lien au comptable que vous souhaitez inviter",
  "Choisir un cabinet comptable": "Choisir un cabinet comptable",
  "Vous n'avez pas encore de cabinet comptable, créez-en une pour commencer.": "Vous n'avez pas encore de cabinet comptable, créez-en un pour commencer.",
  "précédent": "Précédent",
  "générer un lien": "Générer un lien",
  "Lien à partager": "Lien à partager",
  "Envoyer par email": "Envoyer par email",
  "Saisir l'adresse électronique": "Saisir l'adresse électronique",
  "Envoyer": "Envoyer",
  "Email envoyé": "Email envoyé",
  "Inviter une entreprise pour votre cabinet comptable": "Inviter une entreprise à votre cabinet comptable",
  "Envoyez ce lien au entreprise que vous souhaitez inviter": "Envoyez ce lien à l'entreprise que vous souhaitez inviter",
  "Inviter un cabinet comptable": "Inviter un cabinet comptable",
  "Inviter un cabinet comptable pour votre entreprise": "Inviter un cabinet comptable à votre entreprise",
  "Envoyez ce lien au cabinet comptable que vous souhaitez inviter": "Envoyez ce lien au cabinet comptable que vous souhaitez inviter",
  "Choisir une entreprise": "Choisir une entreprise",
  "Vous n'avez pas encore d'entreprise, créez-en une pour commencer.": "Vous n'avez pas encore d'entreprise, créez-en une pour commencer.",
  "Nombre du clients": "Nombre de clients",
  "cabinet comptable supprimé avec succès": "Cabinet comptable supprimé avec succès",
  "Currency": "Devise",
  "général": "Général",
  "Invitation": "Invitation",
  "Veuillez choisir d'accepter ou de refuser l'invitation": "Veuillez choisir d'accepter ou de refuser l'invitation",
  "Inviter un cabinet": "Inviter un cabinet",
  "invité": "Invité",
  "Profile": "Profil",
  "Choisissez un mot de passe robuste afin de protéger vos informations et votre vie privée": "Choisissez un mot de passe robuste afin de protéger vos informations et votre vie privée",
  "Save": "Sauvegarder",
  "Entreprise": "Entreprise",
  "Avertissement": "Avertissement",
  "Vous avez téléchargé avec succès": "Vous avez téléchargé avec succès",
  "Pour terminer le processus, veuillez fournir les informations nécessaires concernant vos documents.": "Pour terminer le processus, veuillez fournir les informations nécessaires concernant vos documents.",
  "Settings": "Paramètres",
  "Archive": "Archive",
  "Entreprises associées": "Entreprises associées",
  "Mes cabinets": "Mes cabinets",
  "Cabinets associés": "Cabinets associés",
  "No statements available": "Aucun relevé n'est disponible",
  "No file has been processed yet": "Aucun document n'a encore été traité",
  "Ajouter une entreprise": "Ajouter une entreprise",
  "Ajouter une agence de comptabilité": "Ajouter une agence de comptabilité",
  "Email de l'entreprise": "Email de l'entreprise",
  "{count} file(s) uploaded successfully": "{count} fichier(s) téléchargé(s) avec succès",
  "Seen by": "Vu par",
  "Delivered": "Delivré",
  "User Id": "Identifiant de l'utilisateur",
  "Load More": "Charger plus",
  "Mark all as read": "Tout marquer comme lu",
  "Subscribe to {app}": "S'abonner à {app}",
  "Your free plan expires {expiresAt}, start subscribing to enjoy our features": "Votre plan gratuit expire {expiresAt}, commencez à vous abonner pour profiter de nos fonctionnalités.",
  "{app} plan": "{app} plan",
  "Pour pouvoir ajouter des comptables à cette entreprise veulliez vous abonner à {app}": "Pour pouvoir ajouter des comptables à cette entreprise veulliez vous abonner à {app}",
  "Unblock all your messages and files": "Débloquez tous vos messages et fichiers",
  "Subscribe to our pack to benefit of {app} features": "Abonnez vous à notre pack pour bénéficier des fonctionnalités de {app}",
  "pack": "pack",
  "What you get from our plan": "Ce que vous apporte notre plan",
  "feature.0": "5 Go de stockage",
  "feature.1": "Scan de documents illimité avec intelligence artificielle",
  "feature.2": "Cabinets comptables illimitées",
  "feature.3": "Entreprises illimitées",
  "Subscribe": "Acheter",
  "Your subscription is expired, please renew your subscription plan": "Votre abonnement est expiré, veuillez renouveler votre plan d'abonnement",
  "on": "le",
  "at": "à",
  "View document": "Voir le document",
  "Restore version": "Restaurer la version",
  "Version history": "Historique des versions",
  "Current version": "Version actuelle",
  "Older versions": "Versions précédentes",
  "versions": "versions",
  "Restoring this version will delete all other versions, including the current one.": "La restauration de cette version supprimera toutes les versions créées après, y compris la version actuelle.",
  "Disassociating {user} will prevent him/her from accessing {company} and remove him/her from your list of collaborators": "La dissociation de {user} l'empêchera d'accéder à {company} et le supprimera de votre liste de collaborateurs",
  "Collaborators": "Collaborateurs",
  "aucun collaborateur n'a été ajouté": "aucun collaborateur n'a été ajouté",
  "Invitez vos collaborateur pour profiter de la plateforme": "Invitez vos collaborateur pour profiter de la plateforme",
  "Dissocier le collaborateur": "Dissocier le collaborateur",
  "Inviter un collaborateur": "Inviter un collaborateur",
  "Inviter un collaborateur pour votre entreprise": "Inviter un collaborateur pour votre entreprise",
  "Envoyez ce lien au collaborateur que vous souhaitez inviter": "Envoyez ce lien au collaborateur que vous souhaitez inviter",
  "collaborator": "collaborateur",
  "Profile picture": "Photo de profil",
  "email": "courriel",
  "sent": "envoyé",
  "Check your inbox": "Vérifiez votre boîte de réception",
  "for our confirmation email and follow the steps to complete the process": "pour recevoir notre courriel de confirmation et suivez les étapes pour compléter le processus.",
  "resend": "renvoyer",
  "configure your email on your services": "configurer votre email sur vos services",
  "generated email": "courriel généré",
  "This email is for receiving invoices directly into the platform. Please note, it’s designed for incoming invoices only and cannot be used for sending emails or for personal use": "Cet e-mail permet de recevoir des factures directement dans la plateforme. Veuillez noter qu'il est conçu pour les factures entrantes uniquement et qu'il ne peut pas être utilisé pour envoyer des courriels ou pour un usage personnel.",
  "How to configure the generated email in your service": "Comment configurer l'email généré dans votre service",
  "test": "test",
  "invoice collector": "collecteur de factures",
  "streamlines invoice management with a unique email address that automatically collects and organizes invoices from various services, making them easily accessible and trackable in one place": "rationalise la gestion des factures grâce à une adresse électronique unique qui collecte et organise automatiquement les factures provenant de différents services, les rendant ainsi facilement accessibles et traçables en un seul endroit.",
  "but first we need your personal email to enable this feature": "mais nous avons d'abord besoin de votre adresse électronique personnelle pour activer cette fonction.",
  "this email will act as an inbox, collecting all messages from the services you're subscribed to": "cet e-mail servira de boîte de réception et recueillera tous les messages provenant des services auxquels vous êtes abonné.",
  "verify": "vérifier",
  "Service test request": "Demande de test de service",
  "we’ve sent you an email test there": "nous vous avons envoyé un test par e-mail",
  "We’ve also sent you a service request test in": "Nous vous avons également envoyé un test de demande de service en",
  "invoice collector page": "page du collecteur de factures",
  "accept it in order to receive a test invoice": "l'accepter pour recevoir une facture test",
  "if you want to read more on this feature": "si vous souhaitez en savoir plus sur cette fonctionnalité",
  "visit our website": "visitez notre site web",
  "received on": "reçu le",
  "accept": "accepter",
  "decline": "déclin",
  "parsed emails": "courriels analysés",
  "multi service invoicing": "facturation multi-services",
  "lets you easily import all your invoices from your subscribed services into one place": "vous permet d'importer facilement toutes les factures de vos services souscrits en un seul endroit",
  "personal email": "courriel personnel",
  "update": "mise à jour",
  "Anuller": "Anuller",
  "Generated email": "Courriel généré",
  "how to configure the generated email in your service": "comment configurer l'email généré dans votre service",
  "keep track of all your service requests, with a clear view of accepted and declined statuses": "garder une trace de toutes vos demandes de service, avec une vue claire des statuts acceptés et refusés",
  "No services": "Pas de services",
  "nothing to show for now, comeback later": "rien à montrer pour l'instant, retour plus tard",
  "pending": "en attente",
  "accepted": "acceptée",
  "declined": "décliné",
  "State": "Etat",
  "feature.4": "Factures illimitées collectées par email",
  "invoice-collector": "Collecteur  de factures"
}