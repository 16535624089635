import { defineStore } from 'pinia'
import { getCompany, getCompanyCollaborators } from 'src/api/company';
import { useUserStore } from './user';
import { useFirmStore } from './firm';

export const useCompanyStore = defineStore('company', {
  state: () => ({
    currentCompanyId: null,
    currentCompany: null,
    collaborators: []
  }),

  getters: {
    getCurrentCompanyId(state) {
      return state.currentCompanyId
    },
    getCurrentCompany(state) {
      return state.currentCompany
    },
    isNotLicensed(){
      return ! this.getCurrentCompany.license.isValid
    },
    getCollaborators(state){
      return state.collaborators;
    }
  },

  actions: {
    async setCurrentCompanyId(companyId) {
      this.currentCompanyId = companyId;
      await this.fetchCurrentCompany();
      this.setUserTypeAndUserableId();
    },
    async fetchCurrentCompany() {
      let { data } = await getCompany();
      this.currentCompany = data.company;
    },
    setUserTypeAndUserableId() {
      let type = null;
      let abilities = this.getCurrentCompany.abilities;

      switch(true) {
        case abilities.own : type = 'company'; break;
        case this.getCurrentCompany.accountant?.is_owned_by_me : type = 'firm_owner';break;
        case abilities.participate : type = 'accountant';break;
        case abilities.collaborate : type = 'collaborator';break;
      }

      useUserStore().setUserType(type);

      //userable is firm
      if (['accountant','firm_owner'].includes(type)) {
        this.setCurrentFirm();
        useUserStore().setUserableId(this.getCurrentCompany.accountant.id);
      }
      //userable is company
      else {
        useUserStore().setUserableId(this.getCurrentCompanyId);
      }

    },
    setCurrentFirm() {
      if (this.currentCompany?.accountant) useFirmStore().setCurrentFirmManually(this.getCurrentCompany.accountant);
    },
    async fetchCompanyCollaborators() {
      let {  data } = await getCompanyCollaborators();
      this.collaborators = data.data;
    }
  }
})